import ss from './App.module.scss';
import Routes from './Routes';
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ErrorPage from './Components/ErrorPage/ErrorPage'
import loaderImage from './assets/images/loader_plane.png'
import Loader from './Components/Loader/Loader';
import { retry } from './Utils/helper'

function App() {
  const [data, setData] = useState()
  const [globalData, setGlobalData] = useState()
  const [error, setError] = useState(false)
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    retry()
    const timeoutID = setTimeout(() => {
      axios.get(`${process.env.REACT_APP_API_URL}/api/pages?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
        setData(response.data)
        setIsLoading(false)
        axios.get(`${process.env.REACT_APP_API_URL}/api/global?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
          setGlobalData(response.data.data.attributes)
          // setLoader2(true)
        }).catch((error) => {
          setError(true)
        })
      }).catch((error) => {
        setError(true)
      })
    }, 15000);
    axios.get(`${process.env.REACT_APP_API_URL}/api/pages?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
      setData(response.data)
      setIsLoading(false)
      clearTimeout(timeoutID);
      axios.get(`${process.env.REACT_APP_API_URL}/api/global?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
        setGlobalData(response.data.data.attributes)
        // setLoader2(true)
      }).catch((error) => {
        setError(true)
      })
    }).catch((error) => {
      setError(true)
    })
  }, [])

  // useEffect(() => {

  //   const timeoutID = setTimeout(() => {
  //     console.log("trying the api call ");
  //     axios.get(`${process.env.REACT_APP_API_URL}/api/pages?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
  //       setData(response.data)
  //     }).catch((error) => {
  //       setError(true)
  //     })


  //   axios.get(`${process.env.REACT_APP_API_URL}/api/pages?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
  //     setData(response.data);
  //     console.log("clearing the timeout");
  //     clearTimeout(timeoutID);
  //     setIsLoading(false)
  //   }).catch((error) => {
  //     setError(true)
  //   })


  // }, [])
  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_API_URL}/api/global?populate=*`, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then((response) => {
  //     setGlobalData(response.data.data.attributes)
  //     // setLoader2(true)
  //   }).catch((error) => {
  //     setError(true)

  //   })
  // }, [])
  return (
    <>
      {isloading ?
        <div className={ss.black_bg}>
          <Loader title="Loading..." percentage="100" Loader_Image={loaderImage} speed={7} />
        </div> :
        <div className={ss.App}>
          {(data && globalData) && <div className={ss.main_bg}>
            <div className={ss.bottom_bg}>
              <div className={ss.main_content}>
                <Routes data={data} globalData={globalData} />
              </div>
            </div>
          </div>}
          {error && <>
            <ErrorPage />
          </>}
        </div>
      }
    </>
  );
}

export default App;