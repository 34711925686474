import React from 'react'
import ss from "./Result.module.scss"
 import plane from "../../assets/images/aereo_wesii.svg";

const Result = ({details,month,resultData,Loader_Image})=>{
   const {regionName,country,continent} = details
    return(
       <section className={ss.result} data-aos="zoom-in-up">
          <div className={ss.result_wrap}>
                <div className={ss.img_plane}>
                {Loader_Image &&(Loader_Image.data ? <img src={`${process.env.REACT_APP_API_URL}${Loader_Image.data.attributes.url}`} alt="aereo wesii"/>:
                 <img src={plane} alt="plane"/>)
                 }             </div>
                {month && <div className={ss.content_box}>
                   <p>{resultData.Success_First_Text}<b> {regionName} <span>(</span>{country}<span>)</span></b> {resultData.Success_Second_Text}</p>
                   <h2 className={ss.available}>{month} {resultData.Booked_Year}</h2>
                </div>}
                {continent.toString().toLowerCase() !== "europe" && month === '' && <div className={ss.content_box}>
                   <p>{resultData.OutofEurope_First_Text}</p>
                   <h2 className={ss.not_available}>{resultData.OutofEurope_Second_Text}</h2>
                </div> }
                {(month === '' && continent.toString().toLowerCase() === "europe") && <div className={ss.content_box}>
                   <p>{resultData.Datepassed_First_Text}</p>
                   <h2 className={ss.not_available}>{resultData.Datepassed_Second_Text}</h2>
                </div>}
             </div>    
       </section>
    )
}

export default Result