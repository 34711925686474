import ss from './SearchLocation.module.scss'
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Select} from "antd";
import axios from 'axios'
import { AutoComplete } from 'antd/lib';
import Notification from '../../Utils/Notification.js'
import { Form } from "antd";
const { Option } = Select;

const SearchLocation = ({searchData}) => {
  let history = useHistory()
  const [selectedOption, setSelectedOption] = useState('')
  const [countries, setCountries] = useState([])
  const [selectedRegion, setSelectedRegion] = useState('Select a Region')
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/countries?populate=*`,
      { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then(response => {
        const countryData = []
        // response.data.data.foreach((data) => {
        //   daata.push({
        //     value: data.attributes.countryname,
        //     region: data.attributes.regions.data,
        //     id: data.id,
        //     countrycode: data.attributes.CountryCode,
        //     continent: data.attributes.continent.data.attributes.continent
        //   })
        // })
        response.data.data.forEach(data => {
            countryData.push({
              value: data.attributes.countryname,
              region: data.attributes.regions? data.attributes.regions.data:[],
              id: data.id,
              countrycode: data.attributes.CountryCode? data.attributes.CountryCode:[],
              continent: data.attributes.continent.data? data.attributes.continent.data.attributes.continent:[]
            })
        });
        setCountries(countryData)
      }).catch((error) => {
        Notification('error','Oops! Something went wrong!','Please try again')
      });

  }, [])

  const handleSelect = (e) => {
    // const values = []
    //   axios.get('http://localhost:1337/api/regions?populate=*').then(response => {
    //   response.data.data.map((data)=>{
    //       if(data.attributes.country.data.attributes.countryname === e){
    //           values.push(data.attributes.regions)
    //       } 
    //   })
    //   setRegions(values)
    setSelectedOption(e)
  }
  const handleOnchange = (e) => {
    setSelectedRegion('Select a Region')
    setSelectedOption('')
  }

  const handleSubmit = () => {
    if (selectedOption && selectedRegion !== 'Select a Region') {
      const countryDetails = countries.find((cn) => cn.value === selectedOption)
      //const continent = 
      const regions = countryDetails.region.find((id) => id.id === selectedRegion)
      const details = {
        country: selectedOption,
        region: selectedRegion,
        regionName: regions.attributes.regions,
        countrycode: countryDetails.countrycode,
        continent: countryDetails.continent
      }
      history.push({ pathname: "/search", state: {details: details} })
    }
    else{
      Notification('info','Please Select Region and Country')
    }
  }

  const filterOption = (inputValue,option)=>{
        if(selectedOption !== '')
        {
          return true
        }
        console.log("opt",option);
        console.log("inp",inputValue);
        if(selectedOption !== '')
        {
          inputValue = ''
          console.log("inside,m",inputValue);

        }
        console.log("inp,m",inputValue);
        const value =  option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        console.log("val",value);
        return value
  }

  const getRegion = ()=>{
    const regionOptions = <Select defaultValue="Sele" value={selectedRegion} onChange={(e) => setSelectedRegion(e)}>
    {selectedOption ?
      // countries.regions[countries.findIndex(x => x.value === selectedOption)].map((values,index)=>{
      //   //  return <Option key={index} value={values}>{values}</Option>
      // })
      countries[countries.findIndex(x => x.value === selectedOption)].region.map((val, index) => {
        return <Option defaultValue key={index} value={val.id}>{val.attributes.regions}</Option>
      })
      : countries.map((val, id) => {
        return val.region.map((valuess) => {
          return <Option value={valuess.id}>{valuess.attributes.regions}</Option>

        })
      })}
  </Select>

  return regionOptions
  }
  return (
    <div className={ss.search_wrap}>
      <div className={ss.serch_bar}>
        <div className={ss.location} >
          <p>{searchData.Search_Text}</p>
        </div>
        <Form className={ss.form_group}>
          <div className={ss.select_box}>
            <div className={ss.serch_box}>
              <label>Country</label>
              <AutoComplete          
                options={countries}
                onChange={(e) => handleOnchange(e)}
                onSelect={(e) => handleSelect(e)}
                // value={selectedOption}
                placeholder="Select a Country"
                filterOption={(inputValue,option)=>filterOption(inputValue,option)}
              />
            </div>
            <div className={ss.serch_box}>
              <label>Region</label>
              {getRegion()}
            </div>
          </div>
          <div className={ss.btn_submit}>
            <button onClick={() => handleSubmit()}>Search</button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default SearchLocation
