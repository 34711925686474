// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#ReactMap_map_canvas__XM-4a path {\n  stroke-width: 1;\n  /* control the countries borders width */\n  stroke: #02070c;\n  /* choose a color for the border */ }\n\n#ReactMap_map_canvas__XM-4a {\n  height: 800px;\n  background-color: salmon; }\n\nsvg path {\n  stroke: #717982; }\n", "",{"version":3,"sources":["webpack://./src/Components/ReactMap/ReactMap.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,wCAAA;EACA,eAAe;EACf,kCAAA,EAAmC;;AAGvC;EACI,aAAa;EACb,wBAAwB,EAAA;;AAG5B;EACI,eAAe,EAAA","sourcesContent":["#map_canvas path {\n    stroke-width: 1;\n    /* control the countries borders width */\n    stroke: #02070c;\n    /* choose a color for the border */\n}\n\n#map_canvas {\n    height: 800px;\n    background-color: salmon;\n}\n\nsvg path {\n    stroke: #717982;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map_canvas": "ReactMap_map_canvas__XM-4a"
};
export default ___CSS_LOADER_EXPORT___;
