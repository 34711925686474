import React from "react";
import ss from "./header.module.scss";
import mainlogo from "../../assets/images/wesii_logo.svg";
import { Button } from "antd";
import Progress from "../ProgressBar/ProgressBar";
import { useHistory } from "react-router-dom";
import { scrollIntoView } from "seamless-scroll-polyfill";

const Header = ({ headerData }) => {
  const handleClick = () => {
    scrollIntoView(document.getElementById("contact"), {
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const handleSignInRegister = () => {
    window.location.href = "https://booking.wesii.dev/dashboard";
  };

  let history = useHistory();
  return (
    <header>
      <div className={ss.top_bar}>
        <div className={ss.logo}>
          {headerData.Logo.data ? (
            <img
              src={`${process.env.REACT_APP_API_URL}${headerData.Logo.data.attributes.url}`}
              alt="logo"
              onClick={() => history.push("/")}
            />
          ) : (
            <img src={mainlogo} alt="logo" onClick={() => history.push("/")} />
          )}
        </div>
        <div className={ss.center_box}>
          <div className={ss.progress}>
            <Progress
              title={`${headerData.Loader_First_Text}`}
              title2={`${headerData.Loader_Second_Text}`}
              percentage="50"
            />
          </div>
        </div>
        <div className={ss.box_right}>
          <div className={ss.top_button}>
            <Button className={ss.btn_top} onClick={handleClick}>
              {headerData.Talk_To_An_Expert_Button}
            </Button>
            <Button className={ss.btn_top} onClick={handleSignInRegister}>
              SIGN IN/REGISTER
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
