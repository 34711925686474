import React, { useState, useEffect } from "react";
import ss from "./LocationForm.module.scss";
import { Form, Input, Checkbox } from "antd";
import { Select } from "antd";
import axios from 'axios'
import Notification from '../../Utils/Notification.js'
import { retry } from "../../Utils/helper";

const { Option } = Select;

const LocationForm = ({ setShwResult, setShow, region, setMonth, details, locationFormData }) => {
  const { regionName, country } = details
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [phone, setPhone] = useState('')
  const onCheckboxChange = (e) => { setChecked(e.target.checked); };
  useEffect(() => {
    retry()
    axios.get(`${process.env.REACT_APP_API_URL}/api/regions/${details.region}?populate=*&sort=month_id`,
      { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } }).then(response => {
        // response.data.data.attributes.availabilities.data.data.map((month)=>{
        // })
        // response.data.data.attributes.availabilities.data.map((mnth)=>{
        //   const today = new Date().toLocaleString('default', { month: 'long' })
        //   if(mnth.attributes.month.toString().toLowerCase() === today.toString().toLowerCase())
        //   {
        //     setMonth(today)
        //   }
        //   return null
        // })
        const d = new Date();
        let thisYear = d.getFullYear();
        let thisMonth= d.getMonth()+1;
        console.log(thisYear);
        console.log(thisMonth);
        let shouldSkip = false;
        response.data.data.attributes.availabilities.data.forEach(mnth => {
          const today = new Date().toLocaleString('en-GB', { month: 'long' })
          if (shouldSkip) {
            return;
          }
          if (thisYear<2023){
            setMonth(mnth.attributes.month.toString());
            console.log(mnth.attributes.month.toString());
            shouldSkip = true;
            return
          }
          else{
            if (mnth.attributes.month_id >= thisMonth) {
              setMonth(mnth.attributes.month.toString());
              console.log(mnth.attributes.month.toString());
              shouldSkip = true;
              return
            }
          }
        })
      }).catch((error) => {
        //openNotification(error)
        Notification('error', 'Something went wrong!!!', 'Please Reload')
      });
  }, [details.region, setMonth])

  const onFinish = (values) => {
    setShow('pending')
    values.region = regionName
    values.country = country
    const datas = { data: values }
    retry()
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/bookings`, datas,
        { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_KEY}` } })
      .then(response => {
        setTimeout(() => {
          setShow('result')
        }, 2000);
      }).catch((error) => {
        setTimeout(() => {
          Notification('error', 'Something went wrong!!!', 'Please Try Again')
          setShow('')
        }, 3000);
      })
  };
  const onFinishFailed = (errorInfo) => {

  };
  return (
    <section className={ss.location_form}>
      <div className={ss.heading}>
        <div dangerouslySetInnerHTML={{ __html: locationFormData.Title }}></div>
        <div dangerouslySetInnerHTML={{ __html: locationFormData.Description }}></div>
      </div>
      <div className={ss.contact_form}>
        <Form
          form={form}
          name="nest-messages"
          className={ss.form_group}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className={ss.form_wrap}>
            <div className={ss.form_box}>
              {/* <Form.Item name={[ 'name']}  label="Nome" rules={[{ required: true }]}>
                    <Input/>
                    </Form.Item> */}
              <Form.Item
                name={["firstname"]}
                rules={[{ required: true, whitespace: true, message: `${locationFormData.Label_FirstName.replace(/[^a-zA-Z]/g, "")} is required` }]}
                id="firstnamefield"
              >
                <div className={ss.formfield}>
                  <Input type="text" name="firstname" id="firstname" required />
                  <label className={ss.label} alt="Nome" placeholder="Nome">
                    {locationFormData.Label_FirstName}
                  </label>
                </div>
              </Form.Item>
            </div>
            <div className={ss.form_box}>
              <Form.Item name={["lastname"]} rules={[{ required: true, whitespace: true, message: `${locationFormData.Label_LastName.charAt(0).toUpperCase() + locationFormData.Label_LastName.replace(/[^a-zA-Z]/g, " ").toLowerCase().slice(1)} is required` }]}>
                <div className={ss.formfield}>
                  <Input type="text" name="firstname" id="firstname" required />
                  <label className={ss.label} alt="Cognome" placeholder="Cognome">
                    {locationFormData.Label_LastName}
                  </label>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className={ss.form_wrap}>
            <div className={ss.form_box}>
              <Form.Item name={["email"]} rules={[{ required: true, type: "email", message: "Enter a valid email" }]}>
                <div className={ss.formfield}>
                  <Input type="text" name="email" id="email" required />
                  <label className={ss.label} alt="E-mail" placeholder="E-mail">
                    {locationFormData.Label_EMail}
                  </label>
                </div>
              </Form.Item>
            </div>
            <div className={ss.form_box}>
              <Form.Item name={["telephone"]} rules={[{ required: true, message: `${locationFormData.Label_Phone.replace(/[^a-zA-Z]/g, "")} is required` }]}>
                <div className={ss.formfield}>
                  <Input className={ss.number} type="text" name="telephone" id="telephone" pattern="[0-9]*"
                    value={phone}
                    onChange={(e) =>
                      setPhone((v) => (e.target.validity.valid ? e.target.value : v))
                    } />
                  <label className={ss.phone_num} alt="Telefono" placeholder="Telefono">
                    {locationFormData.Label_Phone}
                  </label>
                  <span className={ss.plus}>+</span>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className={ss.form_wrap}>
            <div className={ss.form_box}>
              <Form.Item name={["agency"]} rules={[{ required: true, whitespace: true, message: `${locationFormData.Label_Agency.replace(/[^a-zA-Z]/g, "")} is required` }]}>
                <div className={ss.formfield}>
                  <Input type="text" name="agency" id="agency" required />
                  <label className={ss.label} alt="Azienda" placeholder="Azienda">
                    {locationFormData.Label_Agency}
                  </label>
                </div>
              </Form.Item>
            </div>
            <div className={ss.form_box}>
              <div className={ss.search_box}>
                <label>                    {locationFormData.Label_Implants}</label>
                <Form.Item initialValue="Less than 20MW" name={['implants']} label='Implants' rules={[{ required: true }]}>
                  <Select onChange={() => { }}>
                    <Option value="Less than 20MW">{`Less than 20MW`}</Option>
                    <Option value="Greater than 20MW">{`Greater than 20MW`}</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>
          <div className={ss.check_wrap}>
            <Form.Item

              name="checkbox"

              rules={[{ required: !checked, message: checked ? '' : 'Please accept terms and conditions' }]}>

              <Checkbox checked={checked} onChange={onCheckboxChange}>

                <p>{locationFormData.Privacy_Policy}</p> </Checkbox>

            </Form.Item>
          </div>
          <div className={ss.btn_lg}>
            <button className={ss.btn_find}>{locationFormData.Findout_Button}</button>
          </div>
        </Form>
      </div>
    </section>
  );
};

export default LocationForm;
