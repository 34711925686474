import axios from 'axios';
import axiosRetry from 'axios-retry';

export const retry = ()=>{
axiosRetry(axios, {
    retries: 1, // The number of times to retry before failing.
  
    // A callback to further control the delay in milliseconds between retried requests.
    // By default there is no delay between retries.
    retryDelay: (retryCount) => {
      return retryCount * 1000;
    },
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return error;
    }
  })
}